import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/logo.png";

const Nav = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12 mt-2 mb-2">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12">
            <div className="row">
              <div className="col-2 col-lg-2 col-md-2 col-sm-2 col-12">
                <Link className="navbar-brand" to="/">
                  <p className="ntgr-logo-mobile">
                    <img src={Logo} alt="NTGR Logo" className="ntgr-logo" />
                  </p>
                </Link>
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 col-12 ntgr-nav navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse  justify-content-end"
                  id="navbarNav"
                >
                  <ul className="navbar-nav ngtr-nav-top">
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        About us
                      </Link>

                      <ul className="dropdown-menu ntgr-dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/our-dna">
                            Our DNA
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/our-clients">
                            Our Clients
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/partners-and-accreditations"
                          >
                            Partners /Accreditations
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Services
                      </Link>
                      <ul className="dropdown-menu ntgr-dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/asset-management"
                          >
                            Asset Management
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/construction-services"
                          >
                            Construction Services
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="dropdown-item"
                            to="/engineering-design"
                          >
                            Engineering Design
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/turnkey-solutions"
                          >
                            Turnkey Solutions
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Projects
                      </Link>

                      <ul className="dropdown-menu ntgr-dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/projects">
                            Projects
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/gallery">
                            Gallery
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/sherq">
                        SHERQ
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/news">
                        Publications and News
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Nav;
