import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function HandleSubscribe(e) {
    e.preventDefault();
    if (email == "") {
      setEmail("Email must not be empty");
    } else {
      axios({
        url: "https://ntgr.co.za/endpoints/subscribe.php",
        method: "POST",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
        },
      }).then((response) => {
        if (response.status == "200") 
        {
          setErrorMessage("Subscribed");
        }
      });
    }
  }

  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-footer">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12">
            <footer className="py-5">
              <div className="row">
                <div className="col-6 col-md-2 mb-3">
                  <h5>About us</h5>
                  <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                      <Link
                        to="/our-dna"
                        className="nav-link p-0"
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Our DNA
                      </Link>
                    </li>

                    <li className="nav-item mb-2">
                      <Link
                        to="/our-clients"
                        className="nav-link p-0 "
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Our Clients
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/partners-and-accreditations"
                        className="nav-link p-0 "
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Partners
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link to="/projects" className="nav-link p-0 ">
                        Projects
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/gallery"
                        className="nav-link p-0"
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Gallery
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-md-2 mb-3">
                  <h5>Services</h5>
                  <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                      <Link
                        to="/asset-management"
                        className="nav-link p-0"
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Assets Management
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/construction-services"
                        className="nav-link p-0 "
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Construction Services
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/engineering-design"
                        className="nav-link p-0 "
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        EngineeringDesign
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/turnkey-solutions"
                        className="nav-link p-0 "
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Turkey Solutions
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-md-2 mb-3">
                  <h5>News</h5>
                  <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                      <Link
                        to="/news"
                        className="nav-link p-0 "
                        onClick={() => {
                          window.scroll(0, 0);
                        }}
                      >
                        Publications and News
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-5 offset-md-1 mb-3">
                  <form action="https://ntgr.co.za/endpoints/subscribe.php" onSubmit={HandleSubscribe}>
                    <h5>Subscribe to our newsletter</h5>
                    <p>Subscribe to our newsletter and get latest updates.</p>
                    <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                      <label htmlFor="newsletter1" className="visually-hidden">
                        Email address
                      </label>
                      <input
                        id="newsletter1"
                        type="text"
                        className="form-control ntgr-footer-input"
                        placeholder="Email address"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <button
                        className="btn btn-primary ntgr-footer-button"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                    {errorMessage && <p style={{ color: "red" }}>Subscribed</p>}
                  </form>
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p>
                  © {new Date().getFullYear("Y")} NTGR Engineering Projects. All
                  rights reserved.
                </p>
                <ul className="list-unstyled d-flex">
                  {/* <li className="ms-3">
                    <Link className="link-dark" to="/">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Link>
                  </li>
                  <li className="ms-3">
                    <Link className="link-dark" to="/">
                      <FontAwesomeIcon icon={faInstagram} />
                    </Link>
                  </li>
                  <li className="ms-3">
                    <Link className="link-dark" to="/">
                      <FontAwesomeIcon icon={faTwitter} />
                    </Link>
                  </li> */}
                  <li className="ms-3">
                    <a
                      target="_blanl"
                      className="link-dark"
                      href="https://www.linkedin.com/company/ntgr-engineering-projects-cc/"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                </ul>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
