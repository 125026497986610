import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
const NewsPostItem = (props) => {
  const [imgURL, setImageURL] = useState("");
  const [author, setAuthor] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const propTypes = {
    post: PropTypes.object.isRequired,
  };

  const getNewsItemsData = async () => {
    try {
      const { featured_media, author } = props.post;
      const getImageURL = await axios.get(
        `https://ntgr.co.za/backend/wp-json/wp/v2/media/${featured_media}`
      );
      const getAuthor = await axios.get(
        `https://ntgr.co.za/backend/wp-json/wp/v2/users/${author}`
      );
      Promise.all([getImageURL, getAuthor])
        .then((res) => {
          setImageURL(res[0].data.media_details.sizes.full.source_url);
          setAuthor(res[1].data.name);
          setIsLoaded(true);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNewsItemsData();
  });

  const { id, title, excerpt, date } = props.post;

  if (isLoaded) {
    return (
      <div className="col-lg-4 col-md-4 col-12 col-sm-12 mb-5">
        <div className="card">
          <img src={imgURL} className="card-img-top" alt={title.rendered} />
          <div className="card-body">
            <h5 className="card-title">{title.rendered}</h5>
            <p
              className="card-text"
              dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
            ></p>
            <p>
              <small style={{ color: "red" }}>Posted: {date}</small>
            </p>
            <Link
              to={`/newspage/${id}`}
              className="btn btn-primary mt-2 ntgr-news-button"
            >
              Read more
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
export default NewsPostItem;
