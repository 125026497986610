import React, { useState } from "react";
import { Link } from "react-router-dom";
import services_01 from "../Assets/Services/01.jpg";
import services_02 from "../Assets/Services/02.jpg";
import services_03 from "../Assets/Services/03.jpg";
import services_04 from "../Assets/Services/04.jpg";
import "animate.css";
const Services = () => {
  const [overlay, setOverlay] = useState(false);
  const [overlayTwo, setOverlayTwo] = useState(false);
  const [overlayThree, setOverlayThree] = useState(false);
  const [overlayFour, setOverlayFour] = useState(false);
  function MouseOver() {
    setOverlay(true);
  }
  function MouseLeave() {
    setOverlay(false);
  }
  function MouseOver2() {
    setOverlayTwo(true);
  }
  function MouseLeave2() {
    setOverlayTwo(false);
  }
  function MouseOver3() {
    setOverlayThree(true);
  }
  function MouseLeave3() {
    setOverlayThree(false);
  }
  function MouseOver4() {
    setOverlayFour(true);
  }
  function MouseLeave4() {
    setOverlayFour(false);
  }
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-services-home">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10 col-12 col-sm-12">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-10 col-12 col-sm-12">
                <h1 className="text-center">Our Services</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 col-sm-12">
                <Link
                  to="/asset-management"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <div className="row justify-content-center">
                    <div
                      className="col-lg-10 col-md-10 col-10 col-sm-10 ntgr-services-blocks mt-4 p-0"
                      onMouseEnter={MouseOver}
                      onMouseLeave={MouseLeave}
                    >
                      <img src={services_01} width="100%" alt="..." />
                      <div className="ntgr-services-item">
                        <h5>Asset Management</h5>
                      </div>
                      {overlay && (
                        <div className="ntgr-services-overlay animate__animated animate__bounce">
                          <div className="row justify-content-center">
                            <div className="col-lg-10 col-md-10 col-10 col-sm-10">
                              <p>
                                Asset Management Services - Civil, Structural,
                                Mechanical & Electrical
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 col-md-6 col-12 col-sm-12">
                <Link
                  to="/construction-services"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <div className="row justify-content-center">
                    <div
                      className="col-lg-10 col-md-10 col-10 col-sm-10 ntgr-services-blocks mt-4 p-0"
                      onMouseEnter={MouseOver2}
                      onMouseLeave={MouseLeave2}
                    >
                      <img src={services_02} width="100%" alt="..." />
                      <div className="ntgr-services-item">
                        <h5>Construction Services</h5>
                      </div>
                      {overlayTwo && (
                        <div className="ntgr-services-overlay animate__animated animate__bounce">
                          <div className="row justify-content-center">
                            <div className="col-lg-10 col-md-10 col-10 col-sm-10">
                              <p>
                                Rehabilitation, Upgrades and Maintenance Field
                                Services
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 col-12 col-sm-12">
                <Link
                  to="/engineering-design"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <div className="row justify-content-center">
                    <div
                      className="col-lg-10 col-md-10 col-10 col-sm-10 ntgr-services-blocks mt-4 p-0"
                      onMouseEnter={MouseOver3}
                      onMouseLeave={MouseLeave3}
                    >
                      <img src={services_03} width="100%" alt="..." />
                      <div className="ntgr-services-item">
                        <h5>Engineering Design</h5>
                      </div>
                      {overlayThree && (
                        <div className="ntgr-services-overlay animate__animated animate__bounce">
                          <div className="row justify-content-center">
                            <div className="col-lg-10 col-md-10 col-10 col-sm-10">
                              <p>
                                Electrical, Mechanical, Chemical and
                                Civil/Structural Engineering Design
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 col-12 col-sm-12">
                <Link
                  to="/turnkey-solutions"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <div className="row justify-content-center">
                    <div
                      className="col-lg-10 col-md-10 col-10 col-sm-10 ntgr-services-blocks mt-4 p-0"
                      onMouseEnter={MouseOver4}
                      onMouseLeave={MouseLeave4}
                    >
                      <img src={services_04} width="100%" alt="..." />
                      <div className="ntgr-services-item">
                        <h5>Turnkey Solutions</h5>
                      </div>
                      {overlayFour && (
                        <div className="ntgr-services-overlay animate__animated animate__bounce">
                          <div className="row justify-content-center">
                            <div className="col-lg-10 col-md-10 col-10 col-sm-10">
                              <p>
                                Turn-key(EPCM) Engineering and Project
                                Management
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
