import React from "react";
import Slider from "../Components/Slider";
import Services from "../Components/Services";
import Clients from "../Components/Clients";
const Home = () => {
  return (
    <>
      <Slider />
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-home">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10 col-12 col-sm-12">
            <h3>Turnkey Engineering and Asset Management Services</h3>
            <h6 className="text-center">
              Civil, Structural, Mechanical & Electrical
            </h6>
            <p>
              We are an award winning company at the forefront of delivering
              innovative engineering solutions and mining services through
              designing customized solutions that cater to the needs of our
              clients, using our technical experience and leveraging
              collaborations and joint ventures. Our unique value proposition
              covering Civil, Structural, Mechanical, Electrical &
              Instrumentation, is the integration of full asset lifecycle
              activities for; asset creation, operations, maintenance and
              utilization management as well as disposal activities. This is
              enabled by consistent decision-making methods, risk management and{" "}
              technology implementation.
            </p>
          </div>
        </div>
      </div>
      <Services />
      <Clients />
    </>
  );
};
export default Home;
