import React from "react";
import client_01 from "../Assets/clients/01.jpg";
import client_02 from "../Assets/clients/02.jpg";
import client_03 from "../Assets/clients/03.jpg";
import client_04 from "../Assets/clients/04.jpg";
import client_05 from "../Assets/clients/05.jpg";
import client_06 from "../Assets/clients/06.jpg";
import client_07 from "../Assets/clients/07.jpg";
import client_08 from "../Assets/clients/08.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const OurClients = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Our Clients</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  We are trusted by our clients to deliver their projects on
                  time, every time.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <div className="row justify-content-center">
                  <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_01} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_02} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_03} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_04} className="width100" alt="..." />
                      </div>
                    </div>
                  </div>
                  <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_05} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_06} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_07} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_08} className="width100" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurClients;
