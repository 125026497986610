import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";

export default function SliderItem(props) {
  const [imgURL, setImageURL] = useState("");
  const propTypes = {
    post: PropTypes.object.isRequired,
  };
  const getSliderItem = async () => {
    try {
      const { featured_media, author } = props.post;
      const getImageURL = await axios.get(
        `https://ntgr.co.za/backend/wp-json/wp/v2/media/${featured_media}`
      );
      const getAuthor = await axios.get(
        `https://ntgr.co.za/backend/wp-json/wp/v2/users/${author}`
      );
      Promise.all([getImageURL, getAuthor]).then((res) => {
        setImageURL(res[0].data.media_details.sizes.full.source_url);
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSliderItem();
  }, []);
  const { id, title, excerpt, date, categories } = props.post;
  return (
    <>
      {categories == 4 && (
        <div className="carousel-item active">
          <div className="ntgr-transparent">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                <Link to={`/newspage/${id}`} style={{ textDecoration: "none" }}>
                  <h2
                    className="ntgr-slider-h2"
                    dangerouslySetInnerHTML={{ __html: title.rendered }}
                  ></h2>
                </Link>
                <p
                  className="ntgr-slider-p"
                  dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
                ></p>
                <Link
                  to={`/newspage/${id}`}
                  type="button"
                  className="btn btn-primary ntgr-slider-button"
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          <img src={imgURL} className="d-block w-100" alt="MDs Image" />
        </div>
      )}
      {categories == 5 && (
        <div className="carousel-item">
          <div className="ntgr-transparent">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                <Link to={`/newspage/${id}`} style={{ textDecoration: "none" }}>
                  <h2
                    className="ntgr-slider-h2"
                    dangerouslySetInnerHTML={{ __html: title.rendered }}
                  ></h2>
                </Link>
                <p
                  className="ntgr-slider-p"
                  dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
                ></p>
                <Link
                  to={`/newspage/${id}`}
                  type="button"
                  className="btn btn-primary ntgr-slider-button"
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          <img src={imgURL} className="d-block w-100" alt="MDs Image" />
        </div>
      )}
    </>
  );
}
