import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import slider from "../Assets/slider/header.jpg";
import slider_01 from "../Assets/slider/header-01.jpg";
import slider_02b from "../Assets/slider/header-02b.jpg";
import slider_03 from "../Assets/slider/header-03.jpg";
import "animate.css";
import SliderItem from "./SliderItem";
const Slider = () => {
  const [posts, setPosts] = useState([]);
  const [noPosts, setNoPosts] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const getSlider = async () => {
    try {
      await axios
        .get(
          "https://ntgr.co.za/backend/wp-json/wp/v2/posts?categories=4,5",
          {}
        )
        .then((response) => {
          if (response.data.length === 0) {
            setNoPosts(false);
          } else {
            setNoPosts(true);
            setPosts(response.data);
          }
          setIsLoaded(true);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSlider();
  }, []);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 block-header-slider">
            {noPosts ? (
              <>
                {isLoaded ? (
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {isLoaded ? (
                        <>
                          {posts.map((post) => (
                            <SliderItem post={post} key={post.id} />
                          ))}
                        </>
                      ) : (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      <div className="carousel-item  ">
                        <div className="ntgr-transparent">
                          <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                              <Link
                                to="engineering-design"
                                style={{ textDecoration: "none" }}
                              >
                                <h2 className="ntgr-slider-h2">
                                  Engineering Design
                                </h2>
                              </Link>
                              <p className="ntgr-slider-p">
                                Our core engineering services provide complete
                                engineering designs and engineering support
                                services in the Civil, Mechanical, Electrical
                                sector, implementing innovative solutions to add
                                real value using state of the art design
                                technics coupled with our advanced use of BIM
                                platforms.
                              </p>
                              <Link
                                to="engineering-design"
                                type="button"
                                className="btn btn-primary ntgr-slider-button"
                              >
                                Learn more
                              </Link>
                            </div>
                          </div>
                        </div>
                        <img src={slider} className="d-block w-100" alt="..." />
                      </div>
                      <div className="carousel-item ">
                        <div className="ntgr-transparent">
                          <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                              <Link
                                to="construction-services"
                                style={{ textDecoration: "none" }}
                              >
                                {" "}
                                <h2 className="ntgr-slider-h2">
                                  Construction Services
                                </h2>
                              </Link>
                              <p className="ntgr-slider-p">
                                We offer quality infrastructure design, road
                                construction, rehabilitation, upgrades and
                                construction solutions from planning, approval,
                                pre-construction planning and commissioning
                                phases. Our experience spans over 15 years and
                                offer our clients the highest quality of
                                workmanship and experience to each project.
                              </p>
                              <Link
                                to="construction-services"
                                type="button"
                                className="btn btn-primary ntgr-slider-button"
                              >
                                Learn more
                              </Link>
                            </div>
                          </div>
                        </div>
                        <img
                          src={slider_01}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item ">
                        <div className="ntgr-transparent">
                          <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                              <Link
                                to="turnkey-solutions"
                                style={{ textDecoration: "none" }}
                              >
                                {" "}
                                <h2 className="ntgr-slider-h2">
                                  Turnkey Solutions
                                </h2>
                              </Link>
                              <p className="ntgr-slider-p">
                                We have inhouse multidisciplinary engineering
                                teams that provides comprehensive EPC services
                                for clients across diverse industries,
                                delivering capital efficiency and project
                                certainty.
                              </p>
                              <Link
                                to="turnkey-solutions"
                                type="button"
                                className="btn btn-primary ntgr-slider-button"
                              >
                                Learn more
                              </Link>
                            </div>
                          </div>
                        </div>
                        <img
                          src={slider_02b}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item ">
                        <div className="ntgr-transparent">
                          <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                              <Link
                                to="asset-management"
                                style={{ textDecoration: "none" }}
                              >
                                {" "}
                                <h2 className="ntgr-slider-h2">
                                  Asset Management
                                </h2>
                              </Link>
                              <p className="ntgr-slider-p">
                                Our unique value proposition covering Civil,
                                Structural, Mechanical, Electrical &
                                Instrumentation, is the INTEGRATION of full
                                asset lifecycle activities for; asset creation,
                                operations, maintenance and utilization
                                management as well as disposal activities
                              </p>
                              <Link
                                to="asset-management"
                                type="button"
                                className="btn btn-primary ntgr-slider-button"
                              >
                                Learn more
                              </Link>
                            </div>
                          </div>
                        </div>
                        <img
                          src={slider_03}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                ) : (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </>
            ) : (
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active ">
                    <div className="ntgr-transparent">
                      <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                          <Link
                            to="engineering-design"
                            style={{ textDecoration: "none" }}
                          >
                            <h2 className="ntgr-slider-h2">
                              Engineering Design
                            </h2>
                          </Link>
                          <p className="ntgr-slider-p">
                            Our core engineering services provide complete
                            engineering designs and engineering support services
                            in the Civil, Mechanical, Electrical sector,
                            implementing innovative solutions to add real value
                            using state of the art design technics coupled with
                            our advanced use of BIM platforms.
                          </p>
                          <Link
                            to="engineering-design"
                            type="button"
                            className="btn btn-primary ntgr-slider-button"
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                    <img src={slider} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item ">
                    <div className="ntgr-transparent">
                      <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                          <Link
                            to="construction-services"
                            style={{ textDecoration: "none" }}
                          >
                            <h2 className="ntgr-slider-h2">
                              Construction Services
                            </h2>
                          </Link>
                          <p className="ntgr-slider-p">
                            We offer quality infrastructure design, road
                            construction, rehabilitation, upgrades and
                            construction solutions from planning, approval,
                            pre-construction planning and commissioning phases.
                            Our experience spans over 15 years and offer our
                            clients the highest quality of workmanship and
                            experience to each project.
                          </p>
                          <Link
                            to="construction-services"
                            type="button"
                            className="btn btn-primary ntgr-slider-button"
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                    <img src={slider_01} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item ">
                    <div className="ntgr-transparent">
                      <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                          <Link
                            to="turnkey-solutions"
                            style={{ textDecoration: "none" }}
                          >
                            {" "}
                            <h2 className="ntgr-slider-h2">
                              Turnkey Solutions
                            </h2>
                          </Link>
                          <p className="ntgr-slider-p">
                            We have inhouse multidisciplinary engineering teams
                            that provides comprehensive EPC services for clients
                            across diverse industries, delivering capital
                            efficiency and project certainty.
                          </p>
                          <Link
                            to="turnkey-solutions"
                            type="button"
                            className="btn btn-primary ntgr-slider-button"
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                    <img src={slider_02b} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item ">
                    <div className="ntgr-transparent">
                      <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-10 col-sm-10">
                          <Link
                            to="asset-management"
                            style={{ textDecoration: "none" }}
                          >
                            {" "}
                            <h2 className="ntgr-slider-h2">Asset Management</h2>
                          </Link>
                          <p className="ntgr-slider-p">
                            Our unique value proposition covering Civil,
                            Structural, Mechanical, Electrical &
                            Instrumentation, is the INTEGRATION of full asset
                            lifecycle activities for; asset creation,
                            operations, maintenance and utilization management
                            as well as disposal activities
                          </p>
                          <Link
                            to="asset-management"
                            type="button"
                            className="btn btn-primary ntgr-slider-button"
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                    <img src={slider_03} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Slider;
